var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "user-layout-wrapper",
    attrs: {
      id: "userLayout"
    }
  }, [_c("div", {
    staticClass: "container"
  }, [_vm._m(0), _c("div", {
    staticClass: "main"
  }, [_c("el-form", {
    ref: "loginForm",
    attrs: {
      model: _vm.loginForm,
      rules: _vm.rules
    },
    nativeOn: {
      keyup: function keyup($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.submitForm.apply(null, arguments);
      },
      submit: function submit($event) {
        $event.preventDefault();
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "username"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: _vm.$t("login.username")
    },
    model: {
      value: _vm.loginForm.username,
      callback: function callback($$v) {
        _vm.$set(_vm.loginForm, "username", $$v);
      },
      expression: "loginForm.username"
    }
  }, [_c("i", {
    staticClass: "el-input__icon el-icon-user",
    attrs: {
      slot: "suffix"
    },
    slot: "suffix"
  })])], 1), _c("el-form-item", {
    attrs: {
      prop: "password"
    }
  }, [_c("el-input", {
    attrs: {
      type: _vm.lock === "lock" ? "password" : "text",
      placeholder: _vm.$t("login.password")
    },
    model: {
      value: _vm.loginForm.password,
      callback: function callback($$v) {
        _vm.$set(_vm.loginForm, "password", $$v);
      },
      expression: "loginForm.password"
    }
  }, [_c("i", {
    class: "el-input__icon el-icon-" + _vm.lock,
    attrs: {
      slot: "suffix"
    },
    on: {
      click: _vm.changeLock
    },
    slot: "suffix"
  })])], 1), _c("el-form-item", {
    staticStyle: {
      position: "relative"
    },
    attrs: {
      prop: "captcha"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "60%"
    },
    attrs: {
      name: "logVerify",
      placeholder: _vm.$t("login.captcha")
    },
    model: {
      value: _vm.loginForm.captcha,
      callback: function callback($$v) {
        _vm.$set(_vm.loginForm, "captcha", $$v);
      },
      expression: "loginForm.captcha"
    }
  }), _c("div", {
    staticClass: "vPic"
  }, [_vm.picPath ? _c("img", {
    attrs: {
      src: _vm.picPath,
      width: "100%",
      height: "100%",
      alt: _vm.$t("login.captcha")
    },
    on: {
      click: function click($event) {
        return _vm.loginVefify();
      }
    }
  }) : _vm._e()])], 1), _c("el-form-item", [_c("el-button", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v(_vm._s(_vm.$t("login.login-btn")))])], 1)], 1)], 1), _vm._m(1)])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "top"
  }, [_c("div", {
    staticClass: "desc"
  }, [_c("img", {
    staticClass: "logo_login",
    attrs: {
      src: require("@/assets/logo_login.png"),
      alt: ""
    }
  })]), _c("div", {
    staticClass: "header"
  }, [_c("a", {
    attrs: {
      href: "/"
    }
  }, [_c("span", {
    staticClass: "title"
  }, [_vm._v("Zenlayer DNS")])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "footer"
  }, [_c("div", {
    staticClass: "copyright"
  })]);
}];
render._withStripped = true;
export { render, staticRenderFns };